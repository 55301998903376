.logo_img{
    width: 40px;
}

.logo_img_small{
    width: 20px;
}

.nav-header .brand-logo {
    flex-direction: column!important;
  
}

.nav-header .brand-title {
    margin-left: 0.9375rem;
    color: #000;
    margin-top: 0px!important;
}

.dlabnav .metismenu > li a > i {

    margin-right: 2px!important
   
}
.dlabnav .metismenu > li .has-arrow:after {
    right: 1.5rem!important;
}